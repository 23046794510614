// src/components/mobileUi/ServiceCard.js
'use client';

import React from 'react';
import MediaContainer from '../MediaContainer';
import SysImage from './SysImage';

export default function ServiceCard({
  imageSrc,
  imageAlt,
  title,
  description,
  price,
  containerClass = '',
  aspectRatio = 'aspect-square',
}) {
  return (
    <div className="flex w-full cursor-pointer overflow-hidden rounded border shadow">
      <div className="flex-shrink-0">
        <MediaContainer
          aspectRatio={aspectRatio}
          containerClass={`mr-2 ${containerClass}`}
          width="w-32"
          height="h-full" // Ensure both width and height are set consistently
        >
          <SysImage
            src={imageSrc}
            alt={imageAlt}
            objectFit="cover" // Ensures the image fills the container
          />
        </MediaContainer>
      </div>
      <div className="flex flex-col justify-center px-4">
        <h3 className="h4 font-semibold">{title}</h3>
        <p className="line-clamp-2 text-sm text-gray-600">{description}</p>
        <p className="text-sm text-gray-600">{price}</p>
      </div>
    </div>
  );
}
