import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/CustomLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/mobile/HeroSection.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/mobile/pop-up/PopupModalClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/mobile/ReputationSection.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/mobile/ServiceCard.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
