'use client';
import { useState, useEffect } from 'react';
import MediaContainer from '../MediaContainer';
import SectionWrapper from '../SectionWrapper';
import SysImage from './SysImage';
import { logoImages } from '@/lib/images';

export default function ReputationSection() {
  const [overallRating, setOverallRating] = useState(5.0);
  const [totalReviews, setTotalReviews] = useState(246);

  useEffect(() => {
    async function fetchReviewStats() {
      try {
        const res = await fetch('/api/google-reviews');
        const data = await res.json();
        // Only update the rating and total reviews count
        setOverallRating(data.rating || 5.0);
        setTotalReviews(data.user_ratings_total || 246);
      } catch (error) {
        console.error('Error fetching review stats:', error);
        // Keep the default values if there's an error
      }
    }
    fetchReviewStats();
  }, []);

  return (
    <SectionWrapper className="space-y-4 text-center">
      <h2 className="text-xl font-semibold">Our Reputation</h2>
      <MediaContainer>
        <SysImage
          src={logoImages.madGoogleBadge.src}
          alt={logoImages.madGoogleBadge.alt}
          objectFit="contain"
        />
      </MediaContainer>
      <p className="text-base text-gray-700">
        Rated{' '}
        <span className="font-bold text-green-600">
          {overallRating.toFixed(1)}
        </span>{' '}
        out of 5 on Google
      </p>
      <p className="mt-1 text-sm text-gray-500">
        Over {totalReviews} satisfied customers
      </p>
    </SectionWrapper>
  );
}
